.notification {
  width: 100%;
  display: flex !important;
  align-items: center;
}

.notification__message {
  flex-grow: 1;
}

.notification__action {
  margin-right: 1rem;
}

.notification__container {
  position: fixed;
  z-index: 3;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
}

@media screen and (min-width: 768px) {
  .notification__container {
    width: 400px;
    bottom: 2rem;
    left: 2rem;
  }
}
