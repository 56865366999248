.ck.ck-editor__editable {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px !important;
  height: 200px;
}

.ck-focused {
  border-color: #85b7d9 !important;
  box-shadow: none !important;
}

.error > .ck.ck-editor__editable {
  border-color: #e0b4b4 !important;
  background: #fff6f6;
}
